import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import tvFrameImage from '@features/campaigns/builder/assets/images/creative-preview-tv-frame-opt.png';
import { PreviewModalImageOptions } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './PreviewTVSimpleWrapper.module.scss';

interface PreviewTVSimpleWrapperProps {
  imageUrl: string;
  editablePreview?: boolean;
  options?: PreviewModalImageOptions;
}
export function PreviewTVSimpleWrapper({ imageUrl, options, editablePreview }: PreviewTVSimpleWrapperProps) {
  const { t } = useTranslation([i18nNameSpace.CAMPAIGNS]);

  return (
    <div className={styles.container}>
      <img src={tvFrameImage} alt={'tv-frame'} className={styles.tvFrameBackground} />

      <div className={styles.creativePreviewBox}>
        <img src={imageUrl} alt={'creative-preview'} className={styles.creativeImage} />
      </div>

      {editablePreview && (
        <div className={styles.footer}>
          <Button
            data-testid='tv-preview-reject-cta-button'
            className={styles.editButton}
            icon='pi pi-trash'
            onClick={options?.rejectImageCallback}
            text
            severity='danger'
            type='button'
          >
            {t('builder.modals.deleteImage')}
          </Button>
          <Button
            data-testid='tv-preview-accept-cta-button'
            className={styles.deleteButton}
            onClick={options?.acceptImageCallback}
            type='button'
          >
            {t('builder.modals.ok')}
          </Button>
        </div>
      )}
    </div>
  );
}
