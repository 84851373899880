import { ValueOf } from '@shared/types/common.ts';

export const InfoPanelTypes = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
} as const;

export type InfoPanelType = ValueOf<typeof InfoPanelTypes>;
