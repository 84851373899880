import { ValueOf } from '@shared/types/common';

export const FILTERS = {
  FORMAT: 'format',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  STATUS: 'status',
  TYPE: 'type',
} as const;

export type FiltersType = ValueOf<typeof FILTERS>;
