import { AxiosError } from 'axios';
import qs from 'query-string';

import { ConfigResponseType, HttpMethod, HttpMethods } from '@shared/types/apiClient';
import { ApiError } from '@shared/utils/interfaces/error';

import AxiosConfig from './AxiosConfig';

const VALID_STATUS_CODES = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];
export const BUDGET_ERROR_CODE = 409;
export const BONUS_TYPE_ERROR_CODE = 422;

interface ApiRequest<T = unknown> {
  body?: T;
  query?: Record<string, unknown>;
  model?: T;
  headers?: Record<string, string | number>;
}

interface RequestParams<RequestType> {
  method: HttpMethod;
  endpoint: string;
  request?: ApiRequest<RequestType>;
  responseType?: ConfigResponseType;
}

const axios = new AxiosConfig();

class ApiClient {
  public async request<ResponseType = unknown, RequestType = unknown>({
    method,
    endpoint,
    request,
    responseType = 'json',
  }: RequestParams<RequestType>) {
    if (!axios.axiosInstance) {
      return null;
    }

    return axios
      .axiosInstance({
        method,
        headers: request?.headers,
        url: endpoint,
        data: request?.body,
        params: request?.query,
        responseType,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'comma', sort: false });
        },
      })
      .then(({ data, status }) => {
        if (VALID_STATUS_CODES.includes(status)) {
          return data as ResponseType;
        } else {
          if (data) {
            throw new Error(data.message);
          } else {
            return null;
          }
        }
      })
      .catch((error: Error | AxiosError | ApiError) => {
        throw error;
      });
  }

  public get<T>(endpoint: string, query?: Record<string, unknown>, responseType?: ConfigResponseType) {
    return this.request<T>({ method: HttpMethods.Get, endpoint, request: { query }, responseType });
  }

  public post<RequestType, ResponseType = unknown>(
    endpoint: string,
    body?: RequestType,
    headers?: Record<string, string | number>,
  ) {
    return this.request<ResponseType>({ method: HttpMethods.Post, endpoint, request: { body, headers } });
  }

  public put<RequestType, ResponseType = unknown>(endpoint: string, body?: RequestType) {
    return this.request<ResponseType>({ method: HttpMethods.Put, endpoint, request: { body } });
  }

  public delete<RequestType, ResponseType = unknown>(endpoint: string, body?: RequestType) {
    return this.request<ResponseType>({ method: HttpMethods.Delete, endpoint, request: { body } });
  }
}

export const apiClient = new ApiClient();
