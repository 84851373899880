import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from '@features/campaigns/list/components/Table/CampaignsListTable.module.scss';
import { CombinedSequentialId } from '@features/campaigns/shared/components/CombinedSequentialId/CombinedSequentialId.tsx';
import { AccessRights } from '@shared/api/commonApiInterfaces';
import commonStyles from '@shared/components/Table/Table.module.scss';
import { routePaths } from '@shared/router/routePaths';
import { Campaign } from '@shared/utils/interfaces/campaign';

interface NameColumnBodyTemplateProps {
  campaign: Campaign;
}

function NameColumnBodyTemplate({ campaign }: NameColumnBodyTemplateProps) {
  return (
    <div
      className={cn(commonStyles.columnValues, commonStyles.nameValueContainer, {
        [commonStyles.defaultCursor]: !campaign.accessRights.includes(AccessRights.READ),
      })}
    >
      {campaign.accessRights.includes(AccessRights.READ) ? (
        <Link className={commonStyles.nameValue} to={routePaths.campaignDetails.url(campaign.id)}>
          {campaign.name}
        </Link>
      ) : (
        campaign.name
      )}
      <p className={styles.nameIdValue}>
        <CombinedSequentialId
          sequentialId={campaign.sequentialId}
          billingSequentialId={campaign.billingSequentialId}
          type={campaign.type}
        />
      </p>
    </div>
  );
}

export default NameColumnBodyTemplate;
