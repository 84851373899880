import { createContext } from 'react';

// NOTE: Link to PR how to restore feature flag setup https://github.com/MDS-AdTech/admanager-ui/pull/474
export const FeatureFlagPossibleState = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

type FeatureFlagState = keyof typeof FeatureFlagPossibleState;

export const featureFlagsMap = {} as const;

type FeatureFlagKey = keyof typeof featureFlagsMap;

export type FeatureFlags = Record<FeatureFlagKey, FeatureFlagState | undefined>;

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);
