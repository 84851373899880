import { ValueOf } from '@shared/types/common';

export const Permissions = {
  ADVERTISER_LIST_ALL: 'advertiser.list.all',
  CAMPAIGN_CREATE_ALL_ADVERTISERS: 'campaign.create.allAdvertisers',
  CAMPAIGN_CREATE_CROSS_AND_CTV_INTERNAL: 'campaign.create.crossAndCtvInternal',
  CAMPAIGN_CREATE_ASSIGNED_ADVERTISERS: 'campaign.create.assignedAdvertisers',
  CAMPAIGN_CREATE_CTV_SELF_SERVICE: 'campaign.create.ctv.selfService',
  CAMPAIGN_UPDATE_ALL: 'campaign.update.all',
  CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL: 'campaign.update.crossAndCtvInternal.all',
  CAMPAIGN_UPDATE_CROSS_AND_CTV_INTERNAL_ALL_ASSIGNED_ADVERTISERS:
    'campaign.update.crossAndCtvInternal.assignedAdvertisers',
  CAMPAIGN_UPDATE_CTV_OWNED: 'campaign.update.ctv.owned',
  CAMPAIGN_LIST_ALL: 'campaign.list.all',
  CAMPAIGN_LIST_OWNED: 'campaign.list.owned',
  CAMPAIGN_LIST_ASSIGNED_ADVERTISERS: 'campaign.list.assignedAdvertisers',
  CAMPAIGN_UPDATE_ADPRODUCT_DISPLAY_DETAILED_FIELDS: 'campaign.update.adProductDisplay.detailedFields',
  CAMPAIGN_UPLOAD_ADPRODUCT_DISPLAY_CREATIVE_FILES: 'campaign.upload.adProductDisplay.creativeFiles.all',
  CAMPAIGN_UPLOAD_ADPRODUCT_DISPLAY_CREATIVE_FILES_OWNED: 'campaign.upload.adProductDisplay.creativeFiles.owned',
  CAMPAIGN_UPDATE_PRICING_PARAMS: 'campaign.update.pricingParams',
  CAMPAIGN_USE_GROSS_AGENCY_COMMISSION: 'campaign.use.agencyCommissionGross',
  CAMPAIGN_EXPORT_ALL: 'campaign.export.all',
  CAMPAIGN_EXPORT_OWNED: 'campaign.export.owned',
  CAMPAIGN_EXPORT_ASSIGNED_ADVERTISERS: 'campaign.export.assignedAdvertisers',
  CAMPAIGN_UPDATE_APPROVED_ALL: 'campaign.update.approved.all',
  CAMPAIGN_FILTER_BY_TYPE: 'campaign.filter.byType',
  USER_MODIFY: 'user.modify',
} as const;

export type Permission = ValueOf<typeof Permissions>;
