import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

interface LinkTextProps {
  to: string;
  externalLink: boolean;
  className?: string;
  target?: '_blank' | '_self';
}

export function LinkText({
  to,
  children,
  target = '_blank',
  externalLink,
  className,
}: PropsWithChildren<LinkTextProps>) {
  return externalLink ? (
    <a href={to} target={target} rel='noopener noreferrer' className={className}>
      {children}
    </a>
  ) : (
    <Link to={to} className={className} target={target}>
      {children}
    </Link>
  );
}
