import { AccessRight } from '@shared/api/commonApiInterfaces';
import { CampaignId } from '@shared/types/campaign.ts';
import { Status, ValueOf } from '@shared/types/common';

export const CampaignStatuses = {
  DRAFT: 'DRAFT',
  BUDGET_APPROVAL: 'BUDGET_APPROVAL',
  FAILED: 'FAILED',
  IN_APPROVAL: 'IN_APPROVAL',
  APPROVED: 'APPROVED',
  DELIVERING: 'DELIVERING',
  COMPLETED: 'COMPLETED',
  PAUSED: 'PAUSED',
} as const;

export const CampaignTypes = {
  INTERNAL: 'INTERNAL',
  SELF_SERVICE: 'SELF_SERVICE',
} as const;

export const CampaignTypesPrefixes = {
  INTERNAL: 'M',
  SELF_SERVICE: 'S',
};

export type CampaignType = ValueOf<typeof CampaignTypes>;

export const allCampaignStatuses = Object.values(CampaignStatuses);

export const allCampaignTypes = Object.values(CampaignTypes);

export type CampaignStatus = ValueOf<typeof CampaignStatuses>;

export type DraftCampaignStatus = typeof CampaignStatuses.DRAFT;

export const CampaignFormats = {
  DISPLAY: 'DISPLAY',
  VIDEO: 'VIDEO',
} as const;

export type CampaignFormat = ValueOf<typeof CampaignFormats>;

export const allCampaignFormats = Object.values(CampaignFormats);

export const CampaignMediaTypes = {
  CROSS: 'CROSS',
  CTV: 'CTV',
} as const;

export type CampaignMediaType = ValueOf<typeof CampaignMediaTypes>;

export const OperationType = {
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
} as const;

export type Operation = ValueOf<typeof OperationType>;
export type IncludeOperation = ValueOf<typeof OperationType.INCLUDE>;

export const CAMPAIGN_BUILDER_STEPS = {
  STEP_01: 'STEP_01',
  STEP_02: 'STEP_02',
  STEP_03: 'STEP_03',
  STEP_04: 'STEP_04',
  STEP_05: 'STEP_05',
} as const;

export const CreativeServices = {
  FULL: 'FULL',
  LIGHT: 'LIGHT',
} as const;

export type CreativeService = ValueOf<typeof CreativeServices>;

export const CREATIVE_FILE_TYPES = {
  LSHAPE: 'LSHAPE',
  LANDING: 'LANDING',
} as const;

export type PossibleCreativeFileType = keyof typeof CREATIVE_FILE_TYPES;

export interface Advertiser {
  id: string;
  name: string;
  isPreliminaryBlocked: boolean;
  status: Status;
  code: string;
}

interface Brand {
  id: string;
  name: string;
  status: Status;
  code: string;
}

export interface Agency {
  id: string;
  name: string;
  status: Status;
  code: string;
}

export interface BillingContact {
  id: string;
  name: string;
  status: Status;
  code: string;
}

export interface GenericTargetCollectionItem {
  id: string;
  name: string;
  status: Status;
}

export interface Provinces {
  operationType: Operation | null;
  items: Omit<GenericTargetCollectionItem, 'status'>[];
}

export interface AgeGender {
  operationType: Operation | null;
  items: GenericTargetCollectionItem[];
}

export interface InterestSegments {
  operationType: Operation | null;
  items: GenericTargetCollectionItem[];
}

export interface CampaignPricing {
  impressions: number;
  bonusImpressions: number;
  availableImpressions: number;
  estimatedReach: number;
  estimatedFrequency: number;
  cpm: number;
  budget: number;
  targetSize: number;
}

export const InvalidSections = {
  TARGETING: 'TARGETING',
  AD_PRODUCTS: 'AD_PRODUCTS',
  BUDGET: 'BUDGET',
  END_DATE: 'END_DATE',
} as const;

export type InvalidSection = ValueOf<typeof InvalidSections>;

export interface InvalidSectionsItem {
  section: InvalidSection;
  details: string;
}

export interface AdProductDisplay {
  creativeServices: ValueOf<typeof CreativeServices>;
  numberOfCreatives: number;
  lShapeImageUrl: string | null;
  landingImageUrl: string | null;
}

export interface AdProductVideo {
  linearAddressable: boolean;
  vod: boolean;
}

export interface CampaignDetails {
  id: CampaignId;
  sequentialId: number;
  billingSequentialId: number | null;
  name: string;
  creatorName: string;
  createdBy: string;
  isNameAutoGenerated: boolean;
  startDate: string;
  endDate: string;
  isAgencyCommissionGross: boolean;
  advertiser: Advertiser;
  agency: Agency;
  brand: Brand;
  budget: number;
  status: CampaignStatus;
  provinces: Provinces | null;
  contentPackage: GenericTargetCollectionItem | null;
  ageGender: AgeGender | null;
  interestSegments: InterestSegments | null;
  additionalNote: string;
  adProductDisplay: AdProductDisplay | null;
  adProductVideo: AdProductVideo | null;
  pricing: CampaignPricing;
  invalidSections: InvalidSectionsItem[];
  accessRights: AccessRight[];
  type: CampaignType;
  purchaseOrder: string | null;
  billingContact: BillingContact | null;
  authorizedBudget?: number | null;
  mediaType: CampaignMediaType;
}

export type AvailableAdProducts = {
  [k in CampaignFormat]: boolean;
};
