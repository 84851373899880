import { formatNumberWithMaxTwoDigitsAfterSeparator, formatNumberWithCommasInteger } from '@shared/utils/numberFormat';

const percentageValue = 0.1;

export function calculateEstimatedReachMin(estimatedReach: number) {
  return estimatedReach - estimatedReach * percentageValue;
}

export function calculateEstimatedReachMax(estimatedReach: number) {
  return estimatedReach + estimatedReach * percentageValue;
}

export function calculateEstimatedFrequency(impressions: number, range: number) {
  return impressions / range;
}

export function formatMinMaxRange(minValue: number, maxValue: number, isRounded?: boolean) {
  if (isRounded) {
    return `${formatNumberWithCommasInteger(minValue)} - ${formatNumberWithCommasInteger(maxValue)}`;
  } else {
    return `${formatNumberWithMaxTwoDigitsAfterSeparator(minValue)} - ${formatNumberWithMaxTwoDigitsAfterSeparator(maxValue)}`;
  }
}
