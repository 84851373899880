import { CampaignTypes, CampaignTypesPrefixes } from '@features/campaigns/shared/types/campaign.ts';
import { Campaign } from '@shared/utils/interfaces/campaign.ts';

type CombinedSequentialIdProps = Pick<Campaign, 'sequentialId' | 'billingSequentialId'>;
export type CombinedSequentialIdWithPrefixProps = Pick<Campaign, 'sequentialId' | 'billingSequentialId' | 'type'>;

export function createCombinedSequentialId({ sequentialId, billingSequentialId }: CombinedSequentialIdProps) {
  return billingSequentialId ? `${sequentialId} - ${billingSequentialId}` : sequentialId;
}

export function createCombinedSequentialIdWithPrefix({
  sequentialId,
  billingSequentialId,
  type,
}: CombinedSequentialIdWithPrefixProps) {
  const id = createCombinedSequentialId({ sequentialId, billingSequentialId });

  const campaignTypePrefix =
    type === CampaignTypes.INTERNAL ? CampaignTypesPrefixes.INTERNAL : CampaignTypesPrefixes.SELF_SERVICE;

  return `${campaignTypePrefix} - ${id}`;
}
