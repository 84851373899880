import { useTranslation } from 'react-i18next';

import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './NameWithCode.module.scss';

interface NameWithCodeProps {
  name: string;
  code: string;
  disabled?: boolean;
  showCode?: boolean;
}

export default function NameWithCode({ name, code, disabled = false, showCode = true }: NameWithCodeProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  return (
    <>
      {name} {showCode && <span className={styles.code}>({code})</span>}
      {disabled && <span className={styles.code}> ({t('builder.formFields.advertiser.disabledItem')})</span>}
    </>
  );
}
