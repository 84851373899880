import {
  calculateEstimatedReachMax,
  calculateEstimatedReachMin,
  formatMinMaxRange,
} from '@features/campaigns/builder/utils/calculateEstimatedReachFrequency';
import { formatNumberAsMoney } from '@shared/utils/moneyFormat';
import {
  formatNumbersAsCompactNumbers,
  formatNumberWithCommas,
  formatNumberWithMaxTwoDigitsAfterSeparator,
} from '@shared/utils/numberFormat';

interface FormatValueType {
  isMoneyValue: boolean;
  isBudgetCase: boolean;
  isTimeSpent: boolean;
  isFrequencyValue: boolean;
  isEngagementValue?: boolean;
  isActivationValue?: boolean;
  isEstimatedReachValue?: boolean;
  isFrequencyRangeValue?: boolean;
  value?: number;
  valueRange?: number[];
}

export function formatValue({
  isMoneyValue,
  isBudgetCase,
  isTimeSpent,
  isFrequencyValue,
  isEngagementValue,
  isActivationValue,
  isEstimatedReachValue,
  isFrequencyRangeValue,
  valueRange,
  value,
}: FormatValueType): string {
  if (isFrequencyRangeValue && valueRange?.length) {
    const frequencyMin = valueRange[0] / calculateEstimatedReachMax(valueRange[1]);
    const frequencyMax = valueRange[0] / calculateEstimatedReachMin(valueRange[1]);
    return formatMinMaxRange(frequencyMin, frequencyMax);
  }

  if (typeof value === 'undefined') {
    return '-';
  }

  if (isMoneyValue) {
    return formatNumberAsMoney(value, isBudgetCase ? 0 : 2);
  }

  if (isFrequencyValue) {
    return formatNumberWithMaxTwoDigitsAfterSeparator(value);
  }

  if (isEngagementValue) {
    return formatNumbersAsCompactNumbers(value);
  }

  if (isActivationValue) {
    return `${formatNumberWithMaxTwoDigitsAfterSeparator(value)} %`;
  }

  if (isTimeSpent) {
    return `${formatNumbersAsCompactNumbers(value)} sec`;
  }

  if (isEstimatedReachValue) {
    const estimatedReachMin = calculateEstimatedReachMin(value);
    const estimatedReachMax = calculateEstimatedReachMax(value);

    return formatMinMaxRange(estimatedReachMin, estimatedReachMax, true);
  }

  return formatNumberWithCommas(value);
}
