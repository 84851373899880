import { useTranslation } from 'react-i18next';

import { HighlightDataPerformance } from '@features/campaigns/performance/containers/Performance/utils/generateKeyMetricsData';
import { formatValue } from '@features/campaigns/shared/components/Review/Highlight/utils/formatValue';
import { Icon } from '@shared/components/Icon/Icon';
import { IconVariants } from '@shared/components/Icon/IconMapper';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './Highlight.module.scss';

interface HighlightProps {
  label: string;
  value?: number;
  valueRange?: number[];
  isMoneyValue: boolean;
  iconVariant: IconVariants;
  isFrequencyValue?: boolean;
  isEngagementValue?: boolean;
  isActivationValue?: boolean;
  isTimeSpent?: boolean;
  subtitleLabel?: string;
  subtitleValue?: number;
}

export function Highlight({
  label,
  value,
  valueRange,
  iconVariant,
  isMoneyValue,
  isTimeSpent = false,
  isFrequencyValue = false,
  isEngagementValue = false,
  isActivationValue = false,
  subtitleLabel,
  subtitleValue,
}: HighlightProps | HighlightDataPerformance) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const isBudgetCase = label === 'builder.steps.step05.highlights.budget';
  const isEstimatedReachValue = label === 'builder.steps.step05.highlights.estimatedReach';
  const isFrequencyRangeValue = label === 'builder.steps.step05.highlights.estimatedFrequency';

  const formattedValue = formatValue({
    isMoneyValue,
    isBudgetCase,
    isTimeSpent,
    isFrequencyValue,
    isEstimatedReachValue,
    isFrequencyRangeValue,
    value,
    valueRange,
  });
  const formattedSubtitleValue = formatValue({
    isMoneyValue,
    isBudgetCase,
    isTimeSpent,
    isFrequencyValue,
    isEngagementValue,
    isActivationValue,
    value: subtitleValue,
  });

  return (
    <div className={styles.wrapper}>
      <Icon stylesClassName={'highlightsIcon'} variant={iconVariant} iconAltText={`${t(label)} icon`} />

      <div className={styles.content}>
        <p className={styles.label}>{t(label)}</p>
        <p className={styles.value}>{formattedValue}</p>
        {subtitleLabel && <p className={styles.subtitleLabel}>{`${t(subtitleLabel)}: ${formattedSubtitleValue}`}</p>}
      </div>
    </div>
  );
}
