const loadConfig = async () => {
  if (import.meta.env.MODE !== 'production') {
    return {
      API_URL: import.meta.env.VITE_API_URL,
      AD_SPA_CLIENT_ID: import.meta.env.VITE_AD_SPA_CLIENT_ID,
      AD_AUTHORITY: import.meta.env.VITE_AD_AUTHORITY,
      AD_REDIRECT_URI: import.meta.env.VITE_AD_REDIRECT_URI,
      AD_API_SCOPE: import.meta.env.VITE_AD_API_SCOPE,
      APP_RUN_WORKER: import.meta.env.VITE_APP_RUN_WORKER,
      SENTRY_ENABLED: import.meta.env.VITE_SENTRY_ENABLED,
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
      SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
      SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      SENTRY_ERROR_REPLAYS_SAMPLE_RATE: import.meta.env.VITE_SENTRY_ERROR_REPLAYS_SAMPLE_RATE,
      SENTRY_TRACES_SAMPLE_RATE: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
      SENTRY_AUTH_TOKEN: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    };
  }
  const response = await fetch('/config.json');
  return await response.json();
};

const config = await loadConfig();

export default config;
