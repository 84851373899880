import { Dropdown } from 'primereact/dropdown';
import { SyntheticEvent } from 'react';

import { Status } from '@shared/types/common';

export const FieldTypes = {
  ADVERTISER: 'advertiserId',
  BRAND: 'brandId',
  BUDGET: 'budget',
  DATERANGE: 'dateRange',
  AGENCY_COMMISSION: 'isAgencyCommissionGross',
  CAMPAIGN_NAME: 'name',
  IS_NAME_AUTOGENERATED: 'isNameAutoGenerated',
  FORMAT_TYPE_VIDEO: 'formatTypeVideo',
  FORMAT_TYPE_DISPLAY: 'formatTypeDisplay',
  IS_CREATIVE_SERVICES_FULL: 'isCreativeServicesFull',
  NUMBER_OF_CREATIVES: 'numberOfCreatives',
  VIDEO_PRODUCTS_LINEAR_ADDRESSABLE: 'videoProductsLinearAddressable',
  CREATIVE_L_SHAPED_IMAGE: 'creativeImageLShaped',
  CREATIVE_LANDING_IMAGE: 'creativeImageLanding',
  CONTENT_PACKAGE: 'contentPackage',
  PROVINCES: 'provinces',
  AGE_AND_GENDER: 'ageGender',
  INTEREST_SEGMENTS: 'interestSegments',
  CONTENT_PACKAGE_OPERATION_TYPE: 'contentPackageOperationType',
  INTEREST_SEGMENTS_OPERATION_TYPE: 'interestSegmentsOperationType',
  AGE_AND_GENDER_OPERATION_TYPE: 'ageAndGenderOperationType',
  PROVINCES_OPERATION_TYPE: 'provincesOperationType',
  IMPRESSIONS: 'impressions',
  BONUS_IMPRESSIONS: 'bonusImpressions',
  BONUS_IMPRESSIONS_TOGGLE: 'bonusImpressionsToggle',
  CPM: 'cpm',
  ADDITIONAL_NOTE: 'additionalNote',
} as const;

export type FormatTypeFields = typeof FieldTypes.FORMAT_TYPE_VIDEO | typeof FieldTypes.FORMAT_TYPE_DISPLAY;

export const FieldOperationType = {
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
} as const;

export interface DropdownItem {
  name: string;
  value: string;
  code: string;
  disabled: boolean;
  showCode?: boolean;
  nameWithCode: `${string} (${string})`;
  status: Status;
}

export type EnhancedDropdown = Dropdown & { show: () => void };

export interface PreviewModalImageOptions {
  rejectImageCallback: (event: SyntheticEvent) => void;
  acceptImageCallback: () => void;
}
