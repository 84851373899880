import { Trans, useTranslation } from 'react-i18next';

import { CreativePreview } from '@features/campaigns/builder/components/CreativePreview/CreativePreview.tsx';
import { CREATIVE_IMAGE_TYPE } from '@features/campaigns/builder/consts/creativeImage.ts';
import { NoImages } from '@features/campaigns/shared/components/Review/NoImages/NoImages';
import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { AdProductsData } from '@features/campaigns/shared/containers/Review/Review.dto';
import { ReviewSectionProps } from '@features/campaigns/shared/containers/Review/types';
import { CampaignMediaTypes, CampaignStatuses, CampaignTypes } from '@features/campaigns/shared/types/campaign.ts';
import { InfoPanel } from '@shared/components/InfoPanel/InfoPanel.tsx';
import { InfoPanelTypes } from '@shared/components/InfoPanel/InfoPanel.types.ts';
import { LinkText } from '@shared/components/LinkText/LinkText.tsx';
import { Item } from '@shared/components/Review/Item/Item';
import { i18nNameSpace } from '@shared/consts/i18n.ts';
import { routePaths } from '@shared/router/routePaths.ts';

export function AdProducts({
  headerText,
  ctaButtonCallback,
  campaignType,
  campaignStatus,
  data,
  mediaType,
  campaignId,
}: ReviewSectionProps<AdProductsData>) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);

  const landingImageUrl = data.landingImageUrl;
  const lShapeImageUrl = data.lShapeImageUrl;
  const isImageUploaded = landingImageUrl || lShapeImageUrl;
  const isCrossMediaType = mediaType === CampaignMediaTypes.CROSS;

  const isAllImageCreativesUploaded = Boolean(landingImageUrl && lShapeImageUrl);
  const redirectUrl = routePaths.campaignBuilderStep03.url(campaignId);
  const showCreativesUploadReminderInfo = !isAllImageCreativesUploaded && campaignStatus === CampaignStatuses.DRAFT;

  return (
    <SectionWrapper headerText={headerText} ctaButtonCallback={ctaButtonCallback}>
      <div className={'grid'} data-testid='ad-products-section'>
        <div className={'col-6'}>
          <Item label={t('builder.formFields.formatType.label')} value={data.formatType} />
        </div>

        {campaignType === CampaignTypes.INTERNAL && !isCrossMediaType && (
          <>
            <div className={'col-6'}>
              <Item label={t('builder.formFields.creativeServices.label')} value={data.creativeServices} />
            </div>

            <div className={'col-6'}>
              <Item label={t('builder.formFields.numberOfCreatives.label')} value={String(data.numberOfCreatives)} />
            </div>
          </>
        )}

        {campaignType === CampaignTypes.INTERNAL && (
          <div className={'col-6'}>
            <Item label={t('builder.formFields.videoProductsIncluded.label')} value={data.videoProductsIncluded} />
          </div>
        )}

        {campaignType === CampaignTypes.SELF_SERVICE && !isCrossMediaType && (
          <>
            <p className={'subheader'}>{t('review.labels.uploadedCreatives')}</p>
            {isImageUploaded ? (
              <>
                {lShapeImageUrl && (
                  <div className={'col-6'}>
                    <CreativePreview
                      imageUrl={lShapeImageUrl}
                      creativeType={CREATIVE_IMAGE_TYPE.LSHAPE}
                      fileName={'review.creatives.lShapeImage'}
                      headerText={t('builder.formFields.creativeLShapeImage.modalHeaderText')}
                    />
                  </div>
                )}
                {landingImageUrl && (
                  <div className={'col-6'}>
                    <CreativePreview
                      imageUrl={landingImageUrl}
                      creativeType={CREATIVE_IMAGE_TYPE.LANDING}
                      fileName={'review.creatives.landingImage'}
                      headerText={t('builder.formFields.creativeLandingImage.modalHeaderText')}
                    />
                  </div>
                )}
              </>
            ) : (
              <NoImages />
            )}
            {campaignType === CampaignTypes.SELF_SERVICE && !isCrossMediaType && showCreativesUploadReminderInfo && (
              <div className={'col-12'}>
                <InfoPanel
                  type={InfoPanelTypes.INFO}
                  headerText={t('builder.steps.step05.notEnoughCreativesInfo.header')}
                >
                  <p>
                    <Trans
                      t={t}
                      i18nKey={'builder.steps.step05.notEnoughCreativesInfo.content'}
                      components={[<LinkText key={0} externalLink={false} target={'_self'} to={redirectUrl} />]}
                    />
                  </p>
                </InfoPanel>
              </div>
            )}
          </>
        )}
      </div>
    </SectionWrapper>
  );
}
