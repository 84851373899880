import cn from 'classnames';
import { PropsWithChildren } from 'react';

import { InfoPanelType } from '@shared/components/InfoPanel/InfoPanel.types.ts';

import styles from './InfoPanel.module.scss';

interface InfoPanelProps {
  type: InfoPanelType;
  headerText?: string;
  className?: string;
}

export function InfoPanel({ type, headerText, className, children }: PropsWithChildren<InfoPanelProps>) {
  const panelClass = type.toLowerCase();

  return (
    <div className={cn(styles.container, styles[panelClass], className)}>
      <i className={cn('pi pi-exclamation-circle', styles.icon)} />

      <div className={styles.contentContainer}>
        {headerText && <h3 className={styles.header}>{headerText}</h3>}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
