import { AllowAccess } from '@features/auth/ControlAccess/AllowAccess/AllowAccess.tsx';
import {
  CombinedSequentialIdWithPrefixProps,
  createCombinedSequentialId,
  createCombinedSequentialIdWithPrefix,
} from '@features/campaigns/shared/utils/createCombinedSequentialId.ts';
import { Permissions } from '@shared/consts/permissions.ts';

export function CombinedSequentialId({ sequentialId, billingSequentialId, type }: CombinedSequentialIdWithPrefixProps) {
  const combinedSequentialId = createCombinedSequentialId({
    sequentialId,
    billingSequentialId,
  });
  const combinedSequentialIdWithPrefix = createCombinedSequentialIdWithPrefix({
    sequentialId,
    billingSequentialId,
    type,
  });

  return (
    <AllowAccess requiredPermissions={[Permissions.CAMPAIGN_FILTER_BY_TYPE]} fallbackView={combinedSequentialId}>
      {combinedSequentialIdWithPrefix}
    </AllowAccess>
  );
}
