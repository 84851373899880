import { formatMinMaxRange } from '@features/campaigns/builder/utils/calculateEstimatedReachFrequency';

import styles from './ItemValueRange.module.scss';

interface ItemValueRangeProps {
  minValue: number | null;
  maxValue: number | null;
  isRounded?: boolean;
}

export function ItemValueRange({ minValue, maxValue, isRounded }: ItemValueRangeProps) {
  return (
    <p className={styles.wrapper} data-testid='item-value-range'>
      {minValue && maxValue ? formatMinMaxRange(minValue, maxValue, isRounded) : '-'}
    </p>
  );
}
