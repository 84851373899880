import { Button } from 'primereact/button';
import { Trans, useTranslation } from 'react-i18next';

import canale5LogoImage from '@features/campaigns/builder/assets/images/canale-5-logo.svg';
import tvFrameImage from '@features/campaigns/builder/assets/images/creative-preview-tv-frame-opt.png';
import tvProgramPlaceholderImage from '@features/campaigns/builder/assets/images/tv-program-placeholder.jpg';
import { PreviewModalImageOptions } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { InfoPanel } from '@shared/components/InfoPanel/InfoPanel.tsx';
import { InfoPanelTypes } from '@shared/components/InfoPanel/InfoPanel.types.ts';
import { LinkText } from '@shared/components/LinkText/LinkText';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

import styles from './PreviewTVWrapper.module.scss';

interface PreviewTVWrapperProps {
  imageUrl: string;
  editablePreview?: boolean;
  options?: PreviewModalImageOptions;
}

const LANNER_INSTRUCTIONS_URL = 'https://admanager.mediaset.it/it/lanner/#EI4Rfl';

export function PreviewTVWrapper({ imageUrl, editablePreview, options }: PreviewTVWrapperProps) {
  const { t } = useTranslation([i18nNameSpace.CAMPAIGNS]);

  return (
    <div className={styles.container}>
      {editablePreview && (
        <div className={styles.contentContainer}>
          <InfoPanel type={InfoPanelTypes.INFO} headerText={t('builder.lShapePreview.header')}>
            <ul>
              <li key={'hint01'}>
                <Trans
                  t={t}
                  i18nKey={'builder.lShapePreview.content.hint01'}
                  components={[<LinkText externalLink key={0} to={LANNER_INSTRUCTIONS_URL} />]}
                />
              </li>
              <li key={'hint02'}>{t('builder.lShapePreview.content.hint02')}</li>
            </ul>
          </InfoPanel>
        </div>
      )}

      <div className={styles.tvContainer}>
        <img src={tvFrameImage} alt={'tv-frame'} className={styles.tvFrameBackground} />
        <img src={tvProgramPlaceholderImage} alt={'tv-program-placeholder'} className={styles.tvProgramPlaceholder} />
        <div className={styles.creativePreviewBox}>
          <img src={imageUrl} alt={'creative-preview'} className={styles.creativeImage} />
        </div>
        <img src={canale5LogoImage} alt={'canale-5-logo'} className={styles.canale5Logo} />
      </div>

      {editablePreview && (
        <div className={styles.footer}>
          <Button
            data-testid='tv-preview-reject-cta-button'
            className={styles.editButton}
            icon='pi pi-trash'
            onClick={options?.rejectImageCallback}
            text
            severity='danger'
            type='button'
          >
            {t('builder.modals.deleteImage')}
          </Button>
          <Button
            data-testid='tv-preview-accept-cta-button'
            className={styles.deleteButton}
            onClick={options?.acceptImageCallback}
            type='button'
          >
            {t('builder.modals.ok')}
          </Button>
        </div>
      )}
    </div>
  );
}
