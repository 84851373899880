import { TFunction } from 'i18next';

import { CampaignTypes } from '@features/campaigns/shared/types/campaign';
import { I18nNameSpaces } from '@shared/consts/i18n';
import { objectKeys } from '@shared/utils/objectKeys';

export const getCampaignTypeOptions = (t: TFunction<I18nNameSpaces['CAMPAIGNS']>) => {
  const campaignTypeKeysSortedAlphabetically = objectKeys(CampaignTypes).sort();

  return campaignTypeKeysSortedAlphabetically.map((campaignTypeKey) => ({
    label: t(`page.list.campaignType.${campaignTypeKey.toLowerCase()}`),
    value: CampaignTypes[campaignTypeKey],
  }));
};
