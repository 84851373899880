import cn from 'classnames';
import { Dialog } from 'primereact/dialog';

import { PreviewDefaultWrapper } from '@features/campaigns/builder/components/FormFields/common/ImagePreviewModal/components/PreviewDefaultWrapper/PreviewDefaultWrapper.tsx';
import { PreviewTVSimpleWrapper } from '@features/campaigns/builder/components/FormFields/common/ImagePreviewModal/components/PreviewTVSimpleWrapper/PreviewTVSimpleWrapper.tsx';
import { PreviewTVWrapper } from '@features/campaigns/builder/components/FormFields/common/ImagePreviewModal/components/PreviewTVWrapper/PreviewTVWrapper.tsx';
import { PreviewModalImageOptions } from '@features/campaigns/builder/components/FormFields/FieldTypes.ts';
import { CREATIVE_IMAGE_TYPE } from '@features/campaigns/builder/consts/creativeImage';
import { CreativeImageType } from '@features/campaigns/builder/types/creativeImage';

import styles from './ImagePreviewModal.module.scss';

interface ImagePreviewModal {
  className?: string;
  isModalVisible: boolean;
  toggleModalVisibility: () => void;
  creativeType: CreativeImageType;
  imageUrl: string;
  headerText: string;
  editablePreview?: boolean;
  previewModalOptions?: PreviewModalImageOptions;
}

/* 
  The `Dialog` implementation used in this component is customized! 
  In order to enable faster loading of images,
  we had to set `visible` prop to `true`,
  hide the `Dialog` using `display: none` in the styles (`hidden` class),
  to avoid displaying the background of the `Dialog` the `modal` prop is dependent on `isModalVisible`
*/
export function ImagePreviewModal({
  className,
  isModalVisible,
  toggleModalVisibility,
  creativeType = CREATIVE_IMAGE_TYPE.DEFAULT,
  imageUrl,
  headerText,
  editablePreview,
  previewModalOptions,
}: ImagePreviewModal) {
  return (
    <Dialog
      className={cn(styles.wrapper, className, { [styles.noDisplay]: !isModalVisible })}
      visible={true}
      onHide={toggleModalVisibility}
      showHeader
      header={headerText}
      draggable={false}
      resizable={false}
      modal={isModalVisible}
    >
      {creativeType === CREATIVE_IMAGE_TYPE.DEFAULT && <PreviewDefaultWrapper imageUrl={imageUrl} />}
      {creativeType === CREATIVE_IMAGE_TYPE.LSHAPE && (
        <PreviewTVWrapper imageUrl={imageUrl} editablePreview={editablePreview} options={previewModalOptions} />
      )}
      {creativeType === CREATIVE_IMAGE_TYPE.LANDING && (
        <PreviewTVSimpleWrapper imageUrl={imageUrl} editablePreview={editablePreview} options={previewModalOptions} />
      )}
    </Dialog>
  );
}
