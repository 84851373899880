import { format } from 'date-fns';
import { Dispatch } from 'react';

import { apiRoutes } from '@features/campaigns/list/consts/apiRoutes';
import { Actions, CampaignListReducerAction } from '@features/campaigns/list/types/campaignListReducer';
import { apiClient } from '@shared/api/ApiClient.ts';
import { AdManagerApiResponse } from '@shared/api/commonApiInterfaces.ts';
import { DEFAULT_PER_PAGE_LIMIT } from '@shared/components/Table/consts/table';
import { DataTableSortOrder, DataTableStateWithFilters, SortOrder, SortOrders } from '@shared/types/table';
import { Campaign } from '@shared/utils/interfaces/campaign';

interface FetchCampaignsParams {
  dataTableState: DataTableStateWithFilters;
  dispatch: Dispatch<CampaignListReducerAction>;
}

const getSortOrderValue = (sortOrder: DataTableSortOrder): SortOrder => {
  if (sortOrder === -1) {
    return SortOrders.DESC;
  } else if (sortOrder === 1) {
    return SortOrders.ASC;
  }
  return undefined;
};

export const convertToApiParams = ({ sortOrder, page, filters, ...rest }: DataTableStateWithFilters) => {
  return {
    ...rest,
    sortOrder: getSortOrderValue(sortOrder),
    page: (page ?? 0) + 1, // API pagination is numbered starting from 1
    filters: {
      ...filters,
      startDate: filters.startDate && format(new Date(String(filters.startDate)), 'yyyy-MM-dd'),
      endDate: filters.endDate && format(new Date(String(filters.endDate)), 'yyyy-MM-dd'),
    },
  };
};

export async function fetchCampaigns({
  dataTableState,
  dispatch,
}: FetchCampaignsParams): Promise<AdManagerApiResponse<Campaign[]> | null> {
  const {
    page,
    limit,
    sortField,
    sortOrder,
    search,
    filters: { format, status, startDate, endDate, type },
  } = convertToApiParams(dataTableState);

  return await apiClient
    .get<AdManagerApiResponse<Campaign[]>>(apiRoutes.getCampaigns(), {
      limit: limit ?? DEFAULT_PER_PAGE_LIMIT,
      page,
      sortField,
      sortOrder,
      search: search || undefined,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      format: format?.length ? format : undefined,
      status: status?.length ? status : undefined,
      type: type?.length ? type : undefined,
    })
    .then((data) => {
      dispatch({ type: Actions.CHANGE_CAMPAIGNS_COUNT, payload: { campaignsCount: data?.meta?.totalItems || 0 } });
      return data;
    })
    .catch((error) => {
      dispatch({ type: Actions.CHANGE_CAMPAIGNS_COUNT, payload: { campaignsCount: 0 } });
      throw error;
    });
}
