import {
  CampaignDetails,
  CampaignMediaTypes,
  CampaignStatuses,
  CampaignTypes,
  CreativeServices,
  OperationType,
} from '@features/campaigns/shared/types/campaign.ts';
import { AccessRights } from '@shared/api/commonApiInterfaces';
import { STATUS } from '@shared/types/common';

export const mockedCampaignDetails: CampaignDetails = {
  id: 'f3ddac55-a816-4eea-8e1a-4b780cebb60e',
  sequentialId: 35,
  billingSequentialId: 777,
  name: 'Advertiser #3 - Brand #3',
  creatorName: 'John Doe',
  createdBy: '693db38c-733e-4eff-b2d2-3e78abc511b1',
  isNameAutoGenerated: true,
  startDate: '2024-01-23',
  endDate: '2024-02-05',
  isAgencyCommissionGross: true,
  advertiser: {
    id: 'ccff7517-f89e-48e8-aca2-d06d34df298a',
    name: 'Advertiser #3',
    isPreliminaryBlocked: false,
    status: STATUS.ACTIVE,
    code: '12312',
  },
  brand: {
    id: '0224cb28-95ca-463e-adc0-0108b3772913',
    name: 'Brand #3',
    status: STATUS.INACTIVE,
    code: '9333',
  },
  agency: {
    id: '4g54cb28-25ca-443e-vk43-324235234341',
    name: 'Agency #1',
    status: STATUS.ACTIVE,
    code: '9333',
  },
  billingContact: {
    id: '4g54cb28-25ca-443e-vk43-436345345345',
    name: 'BillingContact #1',
    status: STATUS.ACTIVE,
    code: '35236',
  },
  purchaseOrder: '3345564',
  budget: 1000,
  authorizedBudget: 1200,
  status: CampaignStatuses.DRAFT,
  provinces: {
    operationType: OperationType.INCLUDE,
    items: [
      {
        id: 'c1a7479d-6d9c-4bcf-ba7b-10c0ec70c70d',
        name: 'provinces-1',
      },
      {
        id: 'd5148000-3bd3-4976-b3b2-98f07684dab2',
        name: 'provinces-2',
      },
    ],
  },
  contentPackage: {
    id: '028fafe6-c469-4077-9c49-8f474337d506',
    name: 'contentPackage',
    status: STATUS.ACTIVE,
  },
  ageGender: {
    operationType: OperationType.EXCLUDE,
    items: [
      {
        id: 'bbaee67a-4cf1-486b-936e-db5c49bc5ede',
        name: 'ageGender-1',
        status: STATUS.INACTIVE,
      },
      {
        id: 'f15ade5f-b51e-49a2-b6f3-459a7691e678',
        name: 'ageGender-2',
        status: STATUS.ACTIVE,
      },
    ],
  },
  interestSegments: {
    operationType: OperationType.EXCLUDE,
    items: [
      {
        id: 'b73a3d24-1747-4cee-a84e-5dc84b93214b',
        name: 'interestSegments-1',
        status: STATUS.ACTIVE,
      },
      {
        id: 'ea388a96-c2c1-4ee0-8b66-395da17a1fa9',
        name: 'interestSegments-2',
        status: STATUS.INACTIVE,
      },
    ],
  },
  adProductDisplay: {
    creativeServices: CreativeServices.FULL,
    numberOfCreatives: 1,
    lShapeImageUrl: 'https://s3.images.lshapeexample.com',
    landingImageUrl: 'https://s3.images.landingexample.com',
  },
  adProductVideo: {
    linearAddressable: true,
    vod: true,
  },
  additionalNote: 'This is an example',
  invalidSections: [],
  pricing: {
    impressions: 4732955,
    bonusImpressions: 213700,
    availableImpressions: 102896728,
    estimatedReach: 129058,
    estimatedFrequency: 36.67,
    cpm: 21.1,
    budget: 1000,
    targetSize: 148012,
  },
  accessRights: [
    AccessRights.SUBMIT,
    AccessRights.READ,
    AccessRights.EDIT_ALL,
    AccessRights.READ_ADVERTISER_BRAND_CODE,
  ],
  type: CampaignTypes.INTERNAL,
  mediaType: CampaignMediaTypes.CTV,
};

export const createMockedCampaignData = (overrides: Partial<CampaignDetails>): CampaignDetails => ({
  ...mockedCampaignDetails,
  ...overrides,
});
