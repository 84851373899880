import { useTranslation } from 'react-i18next';

import {
  calculateEstimatedFrequency,
  calculateEstimatedReachMax,
  calculateEstimatedReachMin,
} from '@features/campaigns/builder/utils/calculateEstimatedReachFrequency';
import ExportDetails from '@features/campaigns/details/components/CampaignDetails/ImpressionsCalculator/ExportDetails/ExportDetails';
import { AvailabilityAndCostData } from '@features/campaigns/details/containers/CampaignDetailsPage/CampaignDetailsPage.dto';
import { AvailableImpressions } from '@features/campaigns/shared/components/ImpressionsCalculator/AvailableImpressions/AvailableImpressions';
import { Budget } from '@features/campaigns/shared/components/ImpressionsCalculator/Budget/Budget';
import { ItemRow } from '@features/campaigns/shared/components/ImpressionsCalculator/ItemRow/ItemRow';
import { ItemValue } from '@features/campaigns/shared/components/ImpressionsCalculator/ItemValue/ItemValue';
import { ItemValueRange } from '@features/campaigns/shared/components/ImpressionsCalculator/ItemValueRange/ItemValueRange';
import { SectionWrapper } from '@features/campaigns/shared/components/Review/SectionWrapper/SectionWrapper';
import { TargetSizeIcon } from '@features/campaigns/shared/components/TargetSizeIcon/TargetSizeIcon';
import { i18nNameSpace } from '@shared/consts/i18n.ts';

interface ImpressionsCalculatorProps {
  data?: AvailabilityAndCostData;
}

export default function AvailabilityAndCost({ data }: ImpressionsCalculatorProps) {
  const { t } = useTranslation(i18nNameSpace.CAMPAIGNS);
  const shouldDisplayProgressBar = Boolean(data?.impressions) && Boolean(data?.availableImpressions);
  const canShowBonusImpressions = Boolean(data?.bonusImpressions && data.bonusImpressions > 0);

  const estimatedReachMin = data?.estimatedReach ? calculateEstimatedReachMin(data!.estimatedReach!) : null;
  const estimatedReachMax = data?.estimatedReach ? calculateEstimatedReachMax(data!.estimatedReach!) : null;
  const estimatedFrequencyMin =
    Boolean(data?.impressions) && estimatedReachMax
      ? calculateEstimatedFrequency(data!.impressions!, estimatedReachMax)
      : null;

  const estimatedFrequencyMax =
    Boolean(data?.impressions) && estimatedReachMin
      ? calculateEstimatedFrequency(data!.impressions!, estimatedReachMin)
      : null;

  return (
    <SectionWrapper
      headerText={'page.campaignDetails.campaignDetailsTab.sectionTitles.availabilityAndCost'}
      additionalAction={<ExportDetails />}
    >
      {shouldDisplayProgressBar && (
        <AvailableImpressions value={data!.impressions!} maxValue={data!.availableImpressions!} />
      )}

      <ItemRow labelText={t('availabilityAndCosts.impressions')} iconClass={'pi-eye'}>
        <ItemValue value={data?.impressions} />
      </ItemRow>
      {canShowBonusImpressions && (
        <ItemRow
          labelText={t('availabilityAndCosts.bonusImpressions')}
          iconClass={'pi-gift'}
          tooltipText={t('availabilityAndCosts.bonusImpressionsTooltip')}
        >
          <ItemValue value={data?.bonusImpressions} />
        </ItemRow>
      )}
      <ItemRow
        labelText={t('availabilityAndCosts.estimatedReach')}
        iconClass={'pi-users'}
        tooltipText={t('availabilityAndCosts.estimatedReachTooltip')}
      >
        <ItemValueRange isRounded minValue={estimatedReachMin} maxValue={estimatedReachMax} />
      </ItemRow>
      <ItemRow
        labelText={t('availabilityAndCosts.targetSize')}
        tooltipText={t('availabilityAndCosts.targetSizeTooltip')}
        customIcon={<TargetSizeIcon />}
      >
        <ItemValue value={data?.targetSize} />
      </ItemRow>
      <ItemRow
        labelText={t('availabilityAndCosts.estimatedFrequency')}
        iconClass={'pi-users'}
        tooltipText={t('availabilityAndCosts.estimatedFrequencyTooltip')}
      >
        <ItemValueRange minValue={estimatedFrequencyMin} maxValue={estimatedFrequencyMax} />
      </ItemRow>
      <ItemRow labelText={t('availabilityAndCosts.CPM')} iconClass={'pi-money-bill'}>
        <ItemValue value={data?.cpm} moneyFormat />
      </ItemRow>

      <Budget readOnlyValue={data?.budget || 0} />
    </SectionWrapper>
  );
}
