import { formatISO9075 } from 'date-fns';

import {
  CampaignMediaTypes,
  CampaignStatuses,
  CampaignFormats,
  CampaignTypes,
} from '@features/campaigns/shared/types/campaign';
import { AccessRights, AdManagerApiResponse } from '@shared/api/commonApiInterfaces';
import { Campaign } from '@shared/utils/interfaces/campaign';

export const mockedCampaignsList: AdManagerApiResponse<Campaign[]> = {
  items: [
    {
      id: 'ee164aa2-aec9-4423-8000-cae154f772e6',
      name: 'Campaign 1',
      startDate: formatISO9075(new Date(), { representation: 'date' }),
      endDate: formatISO9075(new Date(), { representation: 'date' }),
      status: CampaignStatuses.DRAFT,
      sequentialId: 1234,
      budget: 100000,
      format: [CampaignFormats.DISPLAY],
      accessRights: [AccessRights.EDIT_ALL, AccessRights.READ],
      billingSequentialId: 123,
      creatorName: 'John Web',
      mediaType: CampaignMediaTypes.CROSS,
      type: CampaignTypes.INTERNAL,
    },
    {
      id: 'fe164aa2-aec9-4423-8000-cae154f772e6',
      name: 'Campaign 2',
      startDate: formatISO9075(new Date(), { representation: 'date' }),
      endDate: formatISO9075(new Date(), { representation: 'date' }),
      status: CampaignStatuses.APPROVED,
      sequentialId: 1235,
      budget: 1000,
      format: [CampaignFormats.VIDEO],
      accessRights: [AccessRights.APPROVE, AccessRights.EDIT_ALL, AccessRights.READ],
      billingSequentialId: 124,
      creatorName: 'Collin Barrell',
      mediaType: CampaignMediaTypes.CROSS,
      type: CampaignTypes.INTERNAL,
    },
    {
      id: 'ge164aa2-aec9-4423-8000-cae154f772e6',
      name: 'Campaign 3',
      startDate: formatISO9075(new Date(), { representation: 'date' }),
      endDate: formatISO9075(new Date(), { representation: 'date' }),
      status: CampaignStatuses.BUDGET_APPROVAL,
      sequentialId: 1236,
      budget: 10020,
      format: [CampaignFormats.VIDEO, CampaignFormats.DISPLAY],
      accessRights: [],
      billingSequentialId: 125,
      creatorName: 'Gerard Debug',
      mediaType: CampaignMediaTypes.CROSS,
      type: CampaignTypes.INTERNAL,
    },
  ],
  meta: { totalItems: 3, itemCount: 3, itemsPerPage: 10, totalPages: 1, currentPage: 1 },
};
